import { Link } from "react-router-dom";

const AgreementStatement = ({ id = "agreement-statement" }) => {
  
  return (
    <div className="flex items-center gap-2 my-2">
    <input className="w-4 h-4" id={id} type="checkbox" />
    <label htmlFor={id} className="text-[13px] text-[#969aa1]">
      I agree to the&nbsp;                
      <Link
        to={`/privacy-policy`}
        rel="noreferrer"
        className="text-[13px] underline"
        target="_blank"
      >
        Privacy Policy
      </Link>
      &nbsp;and&nbsp;
      <Link
        to={`/terms&condition`}
        rel="noreferrer"
        className="text-[13px] underline"
        target="_blank"
      >
        Terms of Use
      </Link>
      .
    </label>
  </div>
  );
}
 
export default AgreementStatement;