import clsx from "clsx";
import { BodyText, Title } from "../../quiz10/Typography";

const Ratings = ({ scrollToPayment, isFemale }) => {
  const listData = [
    "Weight loss tailored to your lifestyle",
    "Nutrition guidance and feedback",
    "Personal insights to stay on track",
    "Healthy habits that last",
  ];

  const data = [
    {
      image: "../images/result-page/author1.png",
      name: "Debi Macdonald",
      desc: "Easy to use and to follow. I’m down 15 lbs in 25 days.",
    },
    {
      image: "../images/result-page/author3.png",
      name: "Marcus Hamilton",
      desc: "Lost 8 lbs in one week. I get only a mild hunger pain sometimes. Much better than severe hunger pain i got with doing intermittent fasting.",
    },
    {
      image: "../images/result-page/author2.png",
      name: "Alicia Wheeler Johnson",
      desc: "It’s an easier way of incorporating healthy eating and weight loss into your lifestyle and schedule.",
    },
  ];

  return (
    <>
      <div
        className={clsx(
          isFemale
            ? "bg-white shadow-sm shadow-[#f7585023]"
            : "bg-[#414f4b] shadow-[#414f4b91] shadow-sm",
          "my-10 shadow-lg py-8 px-4 sm:px-8 rounded-lg"
        )}
      >
        <div className="flex justify-center gap-4">
          <img className="" src="../result-checkout/leave.png" alt="leave" />
          <div className="text-center max-w-[300px]">
            <h3
              className={clsx(
                isFemale ? "text-[#135565]" : "text-[#ffffff]",
                "mb-2 text-xl sm:text-3xl font-bold"
              )}
            >
              5 459 675 +
            </h3>
            <BodyText>users started their weight loss journey with us</BodyText>
          </div>
          <img
            className=""
            src="../result-checkout/leave-right.png"
            alt="leave"
          />
        </div>

        <div
          className={clsx(
            "max-w-xs mx-auto my-10 py-3 rounded-md",
            isFemale ? "bg-[#f8f4f4]" : "bg-[#12241f]"
          )}
        >
          <div className="flex flex-row items-center gap-1 justify-center">
            {[1, 2, 3, 4, 5].map((i) => (
              <img
                key={i}
                src="../images/result-page/star-active.svg"
                alt="start"
                className="w-5 rounded-full"
              />
            ))}
          </div>
          <p
            className={clsx(
              isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
              "text-sm text-center mt-1"
            )}
          >
            <span
              className={clsx(
                isFemale ? "text-[#135565]" : "text-[#ffffff]",
                "font-bold text-xl"
              )}
            >
              4.8
            </span>{" "}
            &nbsp; out of 5
          </p>
        </div>

        <p
          className={clsx(
            isFemale ? "text-[#135565]" : "text-[#ffffff]",
            "max-w-xs mx-auto font-semibold text-center"
          )}
        >
          122,000+ 5-star rating from happy users
        </p>
      </div>

      <div className="mb-10">
        <h2
          className={clsx(
            isFemale ? "text-[#135565]" : "text-[#ffffff]",
            "font-semibold text-center text-lg sm:text-xl"
          )}
        >
          Highlights of your personalized plan
        </h2>
        <div className="my-4 max-w-[310px] mx-auto">
          {listData.map((s) => (
            <div
              key={s}
              className={clsx(
                isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
                "flex gap-2 my-2"
              )}
            >
              <img
                className="w-[20px] h-[18px] transform translate-y-[2px]"
                src="../result-checkout/check-mark.png"
                alt="check-mark"
              />
              <p className="text-sm">{s}</p>
            </div>
          ))}
        </div>
      </div>

      <div>
        <p
          className={clsx(
            isFemale ? "text-[#135565]" : "text-[#ffffff]",
            "mb-3 font-semibold"
          )}
        >
          Customer reviews
        </p>
        {data.map((item) => (
          <div
            key={item.name}
            className="bg-transparent rounded-lg px-5 py-4 border-[2px] border-gray-300 shadow-md my-2"
          >
            <div className="flex flex-row w-full items-center">
              <img
                src={item.image}
                alt="author"
                className="w-10 rounded-full border-1 mr-2"
              />

              <div className="flex gap-2 w-full">
                <h5
                  className={clsx(
                    isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
                    "font-semibold flex-1 sm:text-lg ml-2 mr-auto"
                  )}
                >
                  {item.name}
                </h5>

                <div className="hidden flex-row items-center ml-auto gap-[2px] sm:flex">
                  {[1, 2, 3, 4, 5].map((i) => (
                    <img
                      key={i}
                      src="../images/result-page/star-active.svg"
                      alt="start"
                      className="w-4 rounded-full"
                    />
                  ))}
                </div>
              </div>
            </div>

            <p
              className={clsx(
                isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
                "text-sm mt-3"
              )}
            >
              {item.desc}
            </p>
          </div>
        ))}
      </div>

      <div className="flex justify-center my-6 w-full">
        <button
          onClick={() => scrollToPayment(2)}
          className={clsx(
            "w-full rounded-lg p-2 h-[54px] md:h-[60px] font-semibold font-Montserrat text-sm md:text-base",
            "relative flex items-center gap-3 text-center justify-center outline-none",
            "group active:scale-[0.99] transition-all",
            isFemale
              ? "text-white bg-teal-500 hover:bg-teal-600 sm:focus:bg-teal-600"
              : "text-[#12241f] bg-[#d8d8d8] hover:bg-[#ffffff] sm:focus:bg-[#ffffff]"
          )}
          type="button"
        >
          GET MY PLAN
        </button>
      </div>

      <div className="mt-10 shadow-lg bg-gray-200 p-5 rounded-lg">
        <h1
          className={clsx(
            isFemale ? "text-[#135565]" : "text-[#236d5a]",
            "mb-2 font-semibold  text-lg"
          )}
        >
          Money-Back Guarantee
        </h1>
        <p className="text-sm text-gray-600 pr-12">
          We are confident with our service quality and its results. So, if you
          are ready to reach your goals, it’s a risk-free offer.
        </p>
        <div className="flex gap-6">
          <p className="text-sm text-gray-600 mt-5 smx:w-2/3">
            We guarantee you’ll see visible results or you’ll receive a full
            refund within 60 days after your purchase.
          </p>
          <div className="smx:w-1/3 sm:w-1/2 sm:mr-8 smx:flex flex-col justify-center">
            <img
              className="w-[120px]"
              src="../result-checkout/guarantee.png"
              alt="money back guarantee"
            />
          </div>
        </div>
      </div>

      <div className="mt-10 shadow-lg bg-gray-200 p-5 rounded-lg">
        <p className="text-sm text-gray-600">
          You represent that you are over 18 years of age and agree if for
          whatever reason you're unhappy with your plan to contact customer
          support for a refund. To ensure continuous access, your plan will
          renew every quarter at the payment option selected above. If you would
          like a refund for any reason call 1-800-695-5045 or email
          support@myketoslim.com.
        </p>
      </div>
    </>
  );
};

export default Ratings;