import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import creditCardType from "credit-card-type";
import { toast } from "react-toastify";
import { mod10 } from "checkdigit";
import { API_PATH, FUNNEL_TYPE, Loader } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import {
  name,
  planDatas,
  updateCardData,
  selectUserData,
  cardFailed,
} from "../../../redux/quizSlice/quizSlice";
import clsx from "clsx";
import AgreementStatement from "../../../componant/private_components/AgreementStatement";

const regMonth = /^01|02|03|04|05|06|07|08|09|10|11|12$/;
const regYear = /^24|25|26|27|28|29|30|31|32$/;
const regCVV = /^[0-9]{3,4}$/;

const CheckoutSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string(),
  card_number: Yup.string().required("Required"),
  card_exp_month: Yup.string()
    .min(2, "Invalid Month")
    .max(2, "Invalid Month")
    .matches(regMonth, "Invalid Month")
    .required("Required"),
  card_exp_year: Yup.string()
    .min(2, "Invalid Year")
    .max(4, "Invalid Year")
    .matches(regYear, "Invalid Year")
    .required("Required"),
  card_cvc: Yup.string().matches(regCVV, "Invalid Code").required('Required'),
});

const getYear = () => String(new Date().getFullYear()).slice(-2);

const getYears = () => {
  const cYear = Number(getYear());
  let years = [cYear];
  for (let i = 1; i <= 8; i++) {
    years.push(cYear + i);
  }
  return years;
};

const trimCC = (value) => {
  if (!value || !value.trim()) return "";
  return value.replace(/ /g, "");
};

const formatCC = (value) => {
  const card = creditCardType(value)[0];
  const gaps = card?.gaps;
  if (!gaps || !gaps?.length) return value;
  value = trimCC(String(value));
  let formattedString = "";
  for (let i = 0; i < value.length; i++) {
    if (gaps.includes(i)) formattedString += " ";
    formattedString += value[i];
  }
  return formattedString;
};

const PaymentForm = ({
  isFemale,
  setError,
  handlePaymentSubmit,
  formValues,
  hideNameField = false,
  isInitialValid = false,
  agreement = false,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const state = useSelector(selectUserData);
  // const { state, dispatch } = useContext(Context);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const funnelType = FUNNEL_TYPE;

  const initialValues = formValues
    ? { ...formValues, card_number: formatCC(formValues?.card_number) }
    : {
        email: state?.email || "",
        name: "",
        card_number: "",
        card_exp_month: "01",
        card_exp_year: getYear(),
        card_cvc: "",
        card_type: null,
      };

  const paymentUrl = `${API_PATH}/payment/nmi`;

  // const planData = { "id": "2", "plan_name": "1 Month Plan", "planTerm": "1M", "plan_price": "29.99", "currency": "USD", "duration": "30" };
  const planData = state?.planData;

  // const unsubscribe = toast.onChange((payload) => {
  //   if (payload.status === "removed") {
  //     handleSuccess();
  //     unsubscribe();
  //   }
  // });

  useEffect(() => {
    if (formValues) return;
    window.dataLayer.push({
      event: "begin_checkout",
      page: {
        url: window.location.href,
        title: window.location.pathname,
      },
    });
    // return () => {
    //   unsubscribe();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function nextDigit(input, cursorpos, isBackspace) {
    // console.log(input, cursorpos, isBackspace)
    if (isBackspace) {
      if(cursorpos !== 0 && input[cursorpos-1] === ' ') return cursorpos-1
    } else {
      if(input[cursorpos-1] === ' ') return cursorpos+1
    }

    return cursorpos
  }

  const handleCardChange = async (e, setFieldValue) => {
    let cursorPos = e.target.selectionStart;
    const value = trimCC(e.target.value);
    // const value = e.target.value
    const card = creditCardType(value)[0];
    // console.log(card)
    await setFieldValue("card_number", formatCC(value), true);
    // await setFieldValue("card_number", value, true);
    await setFieldValue("card_type", card?.niceType);

    if (value.length === 16 && !validateCard(value)) {
      document.querySelector('[name="card_exp_month"]').focus();
    }

    let isBackspace = (e?.nativeEvent?.data === null) ? true : false
    let nextCusPos = nextDigit(formatCC(value), cursorPos, isBackspace)
    
    e.target.setSelectionRange(nextCusPos, nextCusPos);
  };

  const handleMonthChange = async (e, setFieldValue) => {
    const value = e.target.value;
    await setFieldValue("card_exp_month", value);

    document.querySelector('[name="card_exp_year"]').focus();
  };

  const handleYearChange = async (e, setFieldValue) => {
    const value = e.target.value;
    await setFieldValue("card_exp_year", value);
    document.querySelector('[name="card_cvc"]').focus();
  };

  const handleCvcChange = async (e, values, setFieldValue, setFieldError) => {
    let value = e.target.value;

    const card = creditCardType(values.card_number)[0];
    console.log(card);
    if (value.length > card?.code?.size)
      value = value.slice(0, card?.code?.size);
    await setFieldValue("card_cvc", value);
    if (value.length === card?.code?.size && !hideNameField) {
      document.querySelector('[name="name"]').focus();
    }
  };

  const validateCard = (value) => {
    let error;
    value = trimCC(value);
    const card = creditCardType(value)[0];
    if (!card) {
      error = "Invalid credit card";
      return error;
    }

    // if (!['visa', 'mastercard'].includes(card.type)) {
    //   error = "We accept Visa or MasterCard";
    //   return error;
    // }
    if (["6", "3"].includes(String(value)[0])) {
      error = "We accept Visa or MasterCard";
      return error;
    }

    if(String(value).length > 5){
      const substr = String(value).slice(0, 6);
      if(['517805', '440393'].includes(substr)) {
        error = "Card type not accepted, please use a different card.";
        return error;
      }
    }

    if (String(value).length === 16) {
      if (!mod10.isValid(value)) error = "Invalid credit card";
    }
    return error;
  };

  const showToast = () => {
    const message =
      "Success! Please hold for a one-time Special Offer to BOOST+ your Keto Results";
    toast(message, {
      autoClose: 5000,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      closeButton: false,
      position: "top-center",
    });
  };

  const handleSuccess = () => {
    navigate(`/${funnelType}/ketoboost`);
  };

  const isCardChanged = (prevCard, newCard) => {
    if (!prevCard) return true;
    // return ["card_number", "card_exp_month", "card_exp_year", "card_cvc"].some(
    //   (key) => prevCard[key] !== newCard[key]
    // );
    return prevCard['card_number'] !== newCard['card_number'];
  };

  const handleSubmit = async (allValues, actions) => {
    setError(false);
    let values = { ...allValues, card_number: trimCC(allValues.card_number) };
    console.log(isCardChanged(state.cardData, values));

    if (isCardChanged(state.cardData, values)) {
      dispatch(updateCardData(values));
    } else {
      if (state.cardData.failed > 2) {
        setError({ blocked: true });
        actions.setSubmitting(false);
        return;
      }
    }
    dispatch(name(values.name));

    if (handlePaymentSubmit) return handlePaymentSubmit(values);

    const formData = new FormData();
    for (let key in values) {
      let value = String(values[key]);
      formData.append(key, value);
    }
    formData.append("session_id", planData.session_id);

    try {
      const { data } = await axios.post(paymentUrl, formData);
      if (data.status !== 1) throw Error("Payment failed");
      else {
        dispatch(
          planDatas({
            ...planData,
            txnId: data.txnId,
            orderID: data.orderID,
          })
        );
        setSubmitted(true);
        // showToast();
        handleSuccess();
      }
    } catch (e) {
      console.log(e);
      setError({
        message: `Sorry, it appears your credit card has been declined. Please review
            and update your credit card information below. Most of the time this
            happens because the card number or expiration date were entered
            incorrectly. If you have already checked this data and still
            experienced a decline, you may need to try a different credit card.`,
      });
      dispatch(cardFailed());
    } finally {
      actions.setSubmitting(false);
    }
  };

  const inputStyle = (errors, touched, name) => {
    let classes = `border-[2px] bg-transparent pl-4 p-3 smx:p-[12px] rounded-xl border-gray-300 font-medium mt-[6px] text-[16px]`;
    classes = `${classes} ${
      errors[name] && touched[name]
        ? "text-red-600 placeholder:text-red-300 placeholder:text-opacity-70"
        : `${"text-gray-700"} placeholder:text-opacity-70`
    }`;
    return classes;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CheckoutSchema}
      onSubmit={handleSubmit}
      isInitialValid={isInitialValid}
    >
      {({
        errors,
        values,
        touched,
        isSubmitting,
        handleChange,
        setFieldValue,
        setFieldError,
        isValid,
      }) => (
        <Form className="flex w-full flex-col gap-2 pb-2 relative">
          <div>
            <div className="flex justify-between items-center">
              <label
                htmlFor="card_number"
                className={clsx(
                  "font-medium text-sm ml-[1px]",
                  isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]"
                )}
              >
                Card number
              </label>
            </div>
            <Field
              onChange={(e) => {
                handleCardChange(e, setFieldValue, setFieldError);
              }}
              name="card_number"
              required
              id="card_number"
              type="text"
              maxLength={19}
              inputMode="numeric"
              className={`w-full bg-white ${inputStyle(
                errors,
                touched,
                "card_number"
              )}`}
              placeholder="1234 1234 1234 1234"
              validate={validateCard}
            />
            <ErrorMessage name="card_number">
              {(msg) => {
                if(msg !== 'Card type not accepted, please use a different card.') return null;
                return (
                  <div className="text-xs text-red-500 pl-0 smx:text-[12px]">
                    {msg}
                  </div>
                )
              }}
            </ErrorMessage>
          </div>

          <div className="flex gap-5 justify-between smx:gap-2">
            <div className="w-full">
              <label
                htmlFor="card_exp_month"
                className={clsx(
                  "font-medium text-sm ml-[1px]",
                  isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]"
                )}
              >
                Expiry Date
              </label>
              <div className="flex gap-2">
                <div className="w-1/2 relative">
                  <Field
                    onChange={(e) => {
                      handleMonthChange(e, setFieldValue, setFieldError);
                    }}
                    name="card_exp_month"
                    required
                    id="card_exp_month"
                    type="text"
                    as="select"
                    style={{
                      background: `url("data:image/svg+xml,<svg height='18px' width='18px' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='black'><path stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' /></svg>") no-repeat calc(100% - 10px)`,
                      backgroundColor: "white",
                    }}
                    className={`w-full placeholder:text-xs text-gray-700 appearance-none ${inputStyle(
                      errors,
                      touched,
                      "card_exp_month"
                    )}`}
                    placeholder="MM"
                  >
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </Field>
                  {/* <ErrorMessage name="card_exp_month">
                    {(msg) => (
                      <div className="text-sm text-red-500 pl-0 smx:text-[12px]">
                        {msg}
                      </div>
                    )}
                  </ErrorMessage> */}
                </div>
                <div className="w-1/2 relative">
                  <Field
                    name="card_exp_year"
                    onChange={(e) => {
                      handleYearChange(e, setFieldValue, setFieldError);
                    }}
                    required
                    as="select"
                    id="card_exp_year"
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    style={{
                      background: `url("data:image/svg+xml,<svg height='18px' width='18px' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='black'><path stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' /></svg>") no-repeat calc(100% - 10px)`,
                      backgroundColor: "white",
                    }}
                    className={`w-full placeholder:text-xs text-gray-700 appearance-none ${inputStyle(
                      errors,
                      touched,
                      "card_exp_year"
                    )}`}
                    placeholder="YY"
                  >
                    {getYears().map((y, yi) => (
                      <option key={yi} value={y}>
                        {y}
                      </option>
                    ))}
                  </Field>
                  {/* <YearLogic /> */}
                  {/* <ErrorMessage name="card_exp_year">
                    {(msg) => (
                      <div className="text-sm text-red-500 pl-0 smx:text-[12px]">
                        {msg}
                      </div>
                    )}
                  </ErrorMessage> */}
                </div>
              </div>
            </div>

            <div>
              <label
                htmlFor="card_cvc"
                className={clsx(
                  "font-medium text-sm whitespace-nowrap",
                  isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]"
                )}
              >
                Security code
              </label>
              <div>
                <Field
                  name="card_cvc"
                  id="card_cvc"
                  type="text"
                  onChange={(e) => {
                    handleCvcChange(e, values, setFieldValue, setFieldError);
                  }}
                  // pattern="/^-?\d+\.?\d*$/"
                  className={`w-32 smx:w-20 bg-white ${inputStyle(
                    errors,
                    touched,
                    "card_cvc"
                  )}`}
                  placeholder="***"
                  inputMode="numeric"
                />
                <div className="min-h-[20px]">
                  <ErrorMessage name="card_cvc">
                    {(msg) => (
                      <div className="text-xs text-red-500 pl-1 smx:text-[12px]">
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
              </div>
            </div>
          </div>
          {!hideNameField && (
            <div>
              <label
                htmlFor="name"
                className={clsx(
                  "font-medium text-sm ml-[1px]",
                  isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]"
                )}
              >
                Name on card
              </label>
              <div>
                <Field
                  name="name"
                  id="name"
                  type="text"
                  required
                  className={`w-full bg-white ${inputStyle(
                    errors,
                    touched,
                    "name"
                  )}`}
                  placeholder="Enter name"
                />
              </div>
            </div>
          )}

          {agreement ? <AgreementStatement /> : null}

          <button
            type="submit"
            disabled={isSubmitting || submitted || !isValid}
            className={`bg-teal-500 text-white p-4 rounded-xl font-semibold disabled:bg-gray-300 text-sm mt-3 flex justify-center gap-2 ${
              hideNameField
                ? "absolute -bottom-28 w-[240px] left-1/2 transform -translate-x-1/2"
                : ""
            }`}
          >
            {submitted ? (
              "ORDER SUBMITTED"
            ) : isSubmitting ? (
              <span className="flex justify-center">{Loader}</span>
            ) : (
              <>
                {" "}
                <LockClosedIcon className="w-4" /> Continue Securely
              </>
            )}
          </button>
          {/* <button type='button' onClick={showToast}>Test</button> */}
        </Form>
      )}
    </Formik>
  );
}

export default PaymentForm;
